body {
  margin: 0;
  font-family: "Roboto Slab", "Raleway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}
html, canvas, body, #react-container, #routeContainer {
  font-family: "Roboto Slab", "Raleway";
  height: 100%;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
