.cf-navbar {
  padding: 15px 30px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  border-bottom: 0.5px solid #888;

  font-family: "Roboto Slab";
  font-weight: 800;

  .cf-navbar__links {
    display: flex;
    align-items: center;
  }

  &__left {
    height: 100%;
    max-height: 60px;

    a {
      display: flex;
      align-items: center;
      height: 100%;
    }

    @media screen and (max-width: 576px) {
      width: 250px;
    }
    &--logo {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__links {
    &--link,
    &--link:link,
    &--link:visited {
      font-size: 14px;
      text-transform: uppercase;
      border-radius: 0;
      padding: 1.3em 11px;
      color: #818181;
      text-decoration: none;
      letter-spacing: 1px;
    }

    &--link-primary,
    &--link-primary:link,
    &--link-primary:visited {
      color: #fff;
      background-color: #f04d2a;
      border-radius: 4px;
      padding-left: 25px;
      padding-right: 25px;
      font-weight: 800;
    }

    &--link:hover {
      color: #f04d2a;

      &.cf-navbar__links--link-primary {
        color: #fff;
        background-color: #000;
      }
    }
  }

  .ch-menu {
    position: relative;

    &:hover .ch-sub-menu {
      display: block;
    }
  }

  .ch-sub-menu {
    display: none;
    position: absolute;
    border-top: 6px solid #f04d2a;
    background: #fff;
    padding: 0;
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
    top: 40px;
    left: 0;
    min-width: 140px;
    z-index: 999999;

    &-right {
      right: 0;
      left: auto;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 40px;
      top: -40px;
      left: 0;
    }

    a {
      border: 0;
      padding: 10px 18px;
      transition: none;
      display: block;
      float: none;
      height: auto;
      margin: 0px;
      line-height: 1.4em;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #818181;
      text-decoration: none;
      text-align: left;
      white-space: nowrap;

      &:hover {
        background-color: #f7f7f7;
      }
    }
  }

  .cf-navbar__links {
    @media screen and (max-width: 1300px) {
      transition: right 0.3s ease 0s;
      right: 0px;
      display: block;
      position: fixed;
      top: 0;
      height: 100%;
      z-index: 999999;
      width: 320px;
      max-width: 100%;
      padding-bottom: 30px;
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 14px;
      background: #262626;
      color: #707070;

      &--link,
      &--link:link,
      &--link:visited {
        border-top: 1px solid rgba(255, 255, 255, 0.035);
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgba(255, 255, 255, 0.035);
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;
        font-family: "Raleway", "Helvetica Neue", Arial, sans-serif;
        font-weight: 400;
        padding: 0.9em 20px;
      }
      &--link:focus,
      &--link:hover {
        color: #fff;
      }
    }

    .xmark-icon {
      font-size: 20px;
      color: #aaa;
    }

    .ch-sub-menu {
      &-responsive {
        padding: 0;
        margin: 0;

        a {
          border: 0;
          transition: none;
          display: block;
          float: none;
          height: auto;
          margin: 0px;
          line-height: 1.4em;
          font-size: 14px;
          letter-spacing: 1px;
          text-transform: capitalize;
          color: #818181;
          text-decoration: none;
          padding: 0.9em 20px;

          border-top: 1px solid rgba(255, 255, 255, 0.035);
          border-top-width: 1px;
          border-top-style: solid;
          border-top-color: rgba(255, 255, 255, 0.035);

          font-weight: 400;
          font-family: "Raleway", "Helvetica Neue", Arial, sans-serif;

          &:before {
            content: "-";
            margin: 0 8px;
          }
        }
      }
    }
  }
}

.login-responsive {
  text-transform: uppercase !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.035);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(255, 255, 255, 0.035);
}

.nav-overlay {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
