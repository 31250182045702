.loader_container {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #0275d8;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.transparent-loader {
  background: rgba(2,117,216, 0.4);
}



.loader-blob-1,
.loader-blob-2 {
  width: 70px;
  height: 70px;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-blob-1 {
  left: 20%;
  animation: osc-l 2.5s ease infinite;
}

.loader-blob-2 {
  left: 80%;
  animation: osc-r 2.5s ease infinite;
  background: var(--color-primary);
}

@keyframes osc-l {
  0% {
    left: 20%;
  }

  50% {
    left: 50%;
  }

  100% {
    left: 20%;
  }
}

@keyframes osc-r {
  0% {
    left: 80%;
  }

  50% {
    left: 50%;
  }

  100% {
    left: 80%;
  }
}

.rotate-Icon {
  -webkit-animation: rotationIcon 1.1s infinite linear;
  animation: rotationIcon 1.1s infinite linear;
}

@-webkit-keyframes rotationIcon {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rotationIcon {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}