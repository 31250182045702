.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.sample-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
  justify-content: center;
  padding: 30px;
}

.header-app-title {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  text-shadow: 2px 2px #878080;
  width: 300px;
}
.header-button-buy {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  text-shadow: 2px 2px #878080;
}
.header-product-hunt {
  position: absolute;
  top: 88px;
  z-index: 1000;
}
.header-app {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 50px;
  background: linear-gradient(
    to right,
    rgb(138, 35, 135),
    rgb(233, 64, 87),
    rgb(242, 113, 33)
  );
}

.header-app-editor {
  top: 0px;
  left: 0px;
  height: 500px;
  overflow: hidden;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 50px;
  background: radial-gradient(
    circle,
    rgba(252, 137, 228, 1) 0%,
    rgba(193, 35, 124, 1) 100%
  );
  position: absolute;
}
.content-container {
  position: absolute;
  top: 500px;
  bottom: 0;
  left: 0px;
  right: 0px;
  overflow: auto;
}

.anim1 {
  transform-origin: 50px 50px;
  transform: scale(0.91);
  animation: 2.63158s linear -2.19298s infinite normal forwards running breath-91035fd2-a42e-4c17-b44d-9c52ff0da213;
}

.img-side-left {
  position: absolute;
  left: 170px;
  top: 200px;
  width: 350px;
}

.img-side-right {
  position: absolute;
  right: 170px;
  top: 200px;
  width: 350px;
}

.mapContainer {
  width: 100%;
  height: calc(100vh - 132.2px);
}
.explanationContainerText {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 600px;
  text-align: start;
}

@media (max-width: 480px) {
  .img-side-right {
    display: none;
  }
  .img-side-left {
    display: none;
  }
}
* {
  font-family: inherit;
}
