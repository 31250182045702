.cf-header {
  padding: 10px 30px;
  border-bottom: 1px solid #eee;
  background: #f04d2a;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42.3px;

  &__icons {
    &--link, &--link:link, &--link:visited {
      color: #ffff;
      text-decoration: none;
      font-size: 17;
      margin-left: 10px;
    }

    &--link:hover {
      color: #000000;
    }
  }

  &__right {
    &--link, &--link:link, &--link:visited {
      color: #ffff;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 500;
      font-size: 22px;
      display: flex;
      align-items: center;

     .arrow-icon {
        font-size: 14px;
        margin-right: 5px;
      }
    }

    &--link:hover {
      color: #000000;
    }
  }
}